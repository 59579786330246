.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 5000;
  backdrop-filter: blur(2px);
  bottom: 0;
  left: 0;

  .spinner-icon {
    font-size: 100px;
  }
}

