@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --LOGIN_FORM_SHADOW_COLOR: #bdc6ee;
    --WHITE_COLOR: #fff;
    --BACKGROUND_COLOR: #f7f8fa;
    --BLACK_COLOR: #000;
    --FEEDBACK_BUTTON_COLOR: #002141;
    --TEXT_CORRECTION_COLOR: #c9dbfd;

    /* Primary */
    --PRIMARY_COLOR: #0045e5;
    --PRIMARY_50: #9ebbff;
    --PRIMARY_100: #89adff;
    --PRIMARY_200: #6090ff;
    --PRIMARY_300: #3874ff;
    --PRIMARY_400: #0f57ff;
    --PRIMARY_500: #0045e5;
    --PRIMARY_600: #0034ad;
    --PRIMARY_700: #002375;
    --PRIMARY_800: #00123d;
    --PRIMARY_900: #000105;

    /* Secondary */
    --SECONDARY_COLOR: #002141;
    --SECONDARY_50: #007ef9;
    --SECONDARY_100: #0074e4;
    --SECONDARY_200: #005fbb;
    --SECONDARY_300: #004a93;
    --SECONDARY_400: #00366a;
    --SECONDARY_500: #002141;
    --SECONDARY_600: #000509;
    --SECONDARY_700: #000000;
    --SECONDARY_800: #000000;
    --SECONDARY_900: #000000;

    --TERTIARY_COLOR: #ff3900;
    --TERTIARY_50: #ffebe5;
    --TERTIARY_100: #ffb8a3;
    --TERTIARY_200: #ff987a;
    --TERTIARY_300: #ff7852;
    --TERTIARY_400: #ff5929;
    --TERTIARY_500: #ff3900;
    --TERTIARY_600: #c72c00;
    --TERTIARY_700: #8f2000;
    --TERTIARY_800: #571300;
    --TERTIARY_900: #1f0700;

    /* Orange */
    --ORANGE_50: #ffeee5;
    --ORANGE_100: #ffccb3;
    --ORANGE_200: #ffaa80;
    --ORANGE_300: #f48458;
    --ORANGE_400: #ff661a;
    --ORANGE_700: #802b00;

    /* Green */
    --GREEN_COLOR: #0ff80f;
    --GREEN_50: #f0f9ec;
    --GREEN_200: #bbe3aa;
    --GREEN_300: #9bd582;
    --GREEN_400: #7ac959;
    --GREEN_500: #09e109;
    --GREEN_600: #0dab0d;
    --GREEN_700: #346021;
    --GREEN_900: #011801;

    /* Red */
    --RED_COLOR: #e80d15;
    --RED_50: #ffe6e7;
    --RED_100: #f8cdce;
    --RED_200: #e89b9c;
    --RED_300: #ea595f;
    --RED_400: #d33137;
    --RED_500: #e3121a;
    --RED_600: #b7050b;
    --RED_700: #7a080c;

    /* Yellow */
    --YELLOW_COLOR: #f6c80e;
    --YELLOW_50: #fef9e6;
    --YELLOW_100: #fceeb5;
    --YELLOW_200: #f9d853;
    --YELLOW_300: #f9d853;
    --YELLOW_500: #ddb308;
    --YELLOW_600: #ac8b06;

    /* Gray */
    --GRAY_COLOR: #8f8f8f;
    --GRAY_50: #f2f2f2;
    --GRAY_100: #d9d9d9;
    --GRAY_200: #bfbfbf;
    --GRAY_300: #a6a6a6;
    --GRAY_400: #858484;
    --GRAY_500: #5d5d5d;

    /* Blue */
    --BLUE_COLOR: #3b82f6;
    --BLUE_50: #ebf2fe;
    --BLUE_100: #d7e6fd;
    --BLUE_200: #b0cdfb;
    --BLUE_300: #89b4fa;
    --BLUE_400: #629bf8;
    --BLUE_500: #3b82f6;
    --BLUE_600: #0b61ee;
    --BLUE_700: #084bb8;
    --BLUE_800: #063583;
    --BLUE_900: #041f4d;
}

.bg-primary-light {
    background-color: #0045e50d;
}
