@import '../../../style/GlobalColors';

.main-dashboard-index {
  color: @PRIMARY_400;
  background-color: @PRIMARY_100;
  border-radius: 20px;
  font-size: 24px;
  padding: 8px;
}

.dashboard-index-text {
  color: @PRIMARY_500;
  font-size: large;
  text-decoration: underline
}

